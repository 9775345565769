<script setup lang="ts">
import { AppCalculator } from "@/components/AppCalculator";

const { $tt } = useNuxtApp();
</script>

<template>
  <div class="pt-2 lg:pt-16 bg-neutral-100">
    <AppCalculator />
    <div class="container px-4 md:px-10 xl:px-20 mt-4 pb-[30px] lg:mt-[50px]">
      <p class="text-center text-[10px] font-poppins">
        {{ $tt("landing.calculator.disclaimer") }}
      </p>
    </div>
  </div>
</template>
